<template>
    <page-title
        title="社員一覧"
        icon="bi-person-fill"
    >
        <router-link :to="{name: 'SchoolAdd'}" class="btn btn-outline-primary">
            <i class="bi bi-plus-lg"></i> 新規登録
        </router-link>
    </page-title>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label>氏名</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group col-md-4">
                <select class="form-select" required>
                    <option>-- 全て --</option>
                    <option>カメラマンのみ</option>
                    <option>カメラマン以外</option>
                </select>
            </div>
            <div class="col-3">
                <button class="btn btn-outline-primary"><i class="bi bi-search"></i> 検索</button>
            </div>
        </div>
    </section>

    <section class="section">
        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="text-center">氏名</th>
                    <th class="col-md-2 text-center">略称</th>
                    <th class="col-md-3 text-center">カメラマン</th>
                    <th class="text-center">権限</th>
                    <th class=""></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">片岡高志</td>
                    <td class="text-center">片</td>
                    <td class="text-center"><i class="bi bi-camera-fill"></i></td>
                    <td class="">社員管理</td>
                    <td class="text-center">
                        <router-link :to="{name: 'EmployeeEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">奥村明生</td>
                    <td class="text-center">奥</td>
                    <td class="text-center"><i class="bi bi-camera-fill"></i></td>
                    <td class="">社員管理</td>
                    <td class="text-center">
                        <router-link :to="{name: 'EmployeeEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">友藤美保</td>
                    <td class="text-center">友</td>
                    <td class="text-center"></td>
                    <td class=""></td>
                    <td class="text-center">
                        <router-link :to="{name: 'EmployeeEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">江口悠斗</td>
                    <td class="text-center">江</td>
                    <td class="text-center"><i class="bi bi-camera-fill"></i></td>
                    <td class=""></td>
                    <td class="text-center">
                        <router-link :to="{name: 'EmployeeEdit', params: {id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="d-flex justify-content-center">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                    </li>
                </ul>
            </nav>
        </div>

    </section>

</template>

<script>
import PageTitle from '@/components/PageTitle';


export default {
    name: 'EmployeeList',
    data() {
        return {

        }
    },
    components: {
        PageTitle,
    }
}
</script>

<style scoped>

</style>
